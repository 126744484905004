import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReportsList(data, role = "Admin") {
  // Use toast
  const toast = useToast()

  const refReportListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'reportStatus', sortable: true },
    { key: 'template', sortable: true },
    { key: 'organization', sortable: true },

    { key: 'reportMessage', thClass: "report-message-th-class", tdClass:"report-message-class" },
    { key: 'responseMessage', thClass: "report-message-th-class", tdClass:"report-message-class" },
    { key: 'issuedDate', sortable: true },

  ]
  if(data.template){
    const i = tableColumns.findIndex(x=>x.key == "template")
    tableColumns.splice(i, 1)
  }
  if(role != "SUPER_ADMIN"){
    const i = tableColumns.findIndex(x=>x.key == "organization")
    tableColumns.splice(i, 1)
  }
  const perPage = ref(10)
  const totalReports = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(data.status || "OPEN")
  const organizationFilter = ref(data.organization || null)
  const templateFilter = ref(data.template || null)
  const outputIdFilter = ref(data.outputId || null)

  const dataMeta = computed(() => {
    const localItemsCount = refReportListTable.value ? refReportListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReports.value,
    }
  })

  const refetchData = () => {
    refReportListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, organizationFilter, templateFilter, outputIdFilter], () => {
    refetchData()
  })

  const fetchReports = (ctx, callback) => {
    store
      .dispatch('app-report/fetchReports', {
        q: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        status: statusFilter.value,
        template: templateFilter.value,
        outputId: outputIdFilter.value,
        organizations: organizationFilter.value
      })
      .then(response => {
        callback(response.data.results)
        totalReports.value = response.data.totalResults
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching reports' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveReportStatusVariantAndIcon = status => {
    if (status === 'IN_PROGRESS') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'CLOSED') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'OPEN') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'OPEN') return 'danger'
    if (status === 'IN_PROGRESS') return 'warning'
    if (status === 'CLOSED') return 'success'
    return 'primary'
  }

  return {
    fetchReports,
    tableColumns,
    perPage,
    currentPage,
    totalReports,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportListTable,
    templateFilter,
    outputIdFilter,
    statusFilter,
    organizationFilter,
    resolveReportStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  }
}
